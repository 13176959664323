<template>
    <div class="login">
      <el-row class="el-row">
        <el-col :span="16"
                class="el-colss">
          <el-row class="el-row el-rows">
            <el-col :span="10"
                    class="el-cols">
              <div class="cenformbox">
                <h1>湖南96320转运车管理中心</h1>
                <el-form :model="form"
                         ref="Loginrulesref"
                         :rules="Loginrules">
                  <el-form-item prop="userName">
                    <el-input v-model="form.userName"
                              prefix-icon="el-icon-user-solid"></el-input>
                  </el-form-item>
                  <el-form-item prop="passwd">
                    <el-input class="el-input"
                              type="password"
                              v-model="form.passwd"
                              show-passwd
                              prefix-icon="el-icon-lock"
                              @keyup.enter.native="logintj"></el-input>
                  </el-form-item>
                  <!-- <el-form-item prop="code" class="yzmitem">
                    <el-input
                      class="el-input"
                      type="text"
                      v-model="form.code"
                      prefix-icon="el-icon-mobile"
                    ></el-input>
                    <img src="../assets/logoimg.png" class="img" />
                  </el-form-item>-->
                  <el-form-item>
                    <el-button class="el-button"
                               type="primary"
                               @click="logintj">提交</el-button>
                  </el-form-item>
                </el-form>
              </div>
            </el-col>
            <el-col :span="14"
                    class="el-cols">
             <img src="../../assets/logoimg.png"
                   class="img"
                   @click="getcodeimg" />
              <img />
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </template>
<script>
// import { async } from "q";
// import { log } from "util";
export default {
  name: 'login',
  data () {
    return {
      form: {
        userName: '',
        passwd: ''
        // code: ""
      },
      Loginrules: {
        userName: [{
          required: true,
          message: '请输入用户名',
          trigger: 'blur'
        },
        {
          min: 2,
          max: 16,
          message: '长度在 2 到 16 个字符',
          trigger: 'blur'
        }
        ],
        passwd: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        },
        {
          min: 3,
          max: 18,
          message: '长度在 3 到 18 个字符',
          trigger: 'blur'
        }
        ]
      },
      btnsList: []
    }
  },

  methods: {
    getcodeimg () {

    },
    logintj () {
      this.$refs.Loginrulesref.validate(async valid => {
        if (!valid) return
        const res = await this.$http.post(
          '/account/user/login',
          JSON.parse(JSON.stringify(this.form)))
        console.log(res)
        if (res.data.result !== 'true') return this.$message.error(res.data.resultDesc)
        this.$message({
          message: '登录成功',
          type: 'success',
          duration: '1000'
        })
        sessionStorage.setItem('userId', res.data.data.user.uuid)
        sessionStorage.setItem('token', res.data.data.token)
        sessionStorage.setItem('userName', res.data.data.user.userName)
        sessionStorage.setItem('companyId', res.data.data.user.corpId)
        sessionStorage.setItem('phone', res.data.data.user.mobilePhone)
        sessionStorage.setItem('passwd', res.data.data.user.passwd)
        sessionStorage.setItem('token', res.data.data.token)
        try {
          await this.getorderCompany(res.data.data.user)
        } catch {
          console.log('暂无接口')
        }
        this.getList()
      })
    },
    // 获取order_company
    async getorderCompany (data) {
      console.log(data, 'datas')
      const { uuid, corpId } = data
      const obj = {
        userId: uuid, corpId
      }
      const res = await this.$http.post(
        'userManageServer/area/selectRoleBybaseId',
        obj)
      const dataValue = res.data.data
      this.$store.dispatch('SET_ORDER', dataValue)
    },
    async getList () {
      const {
        data: res
      } = await this.$http.post('/userManageServer/user/selectMenuList', {
        applicationCode: '10000',
        userId: sessionStorage.getItem('userId')
      })
      if (res.statusCode !== '200') return this.$message.error('获取用户菜单失败')
      if (res.data instanceof Array) {
        // var headerNav = res.data.filter(function (item, index, array) {
        //   return item.menuName !== '大屏'
        // })
        var headerNav = res.data
        // var index = null
        // headerNav.forEach((item, i) => {
        //   if (item.MENU_URL.includes('/operation')) {
        //     // headerNav.splice(index, 1)
        //     console.log(i, 'iiiii')
        //     index = i
        //   }
        // })
        // headerNav.splice(index, 1)
        sessionStorage.setItem('headerNav', JSON.stringify(headerNav))
        this.$router.push('/system')
      }
      // 生成路由表
      var asyncRputer = []
      res.data.forEach(item => {
        var obj = {}
        obj.path = item.menuUrl
        obj.name = item.menuUrl.split('/').join('')
        // obj.component = `() => import('@/views${item.menuUrl}${item.menuUrl}.vue')`
        obj.component = eval(`() => import('@/views${item.menuUrl}${item.menuUrl}.vue')`)
        obj.meta = {
          bread: {
            title: item.menuName
          },
          path: obj.path
        }
        obj.children = []
        item.twoMenusList.forEach(i => {
          var ob = {}
          ob.path = i.menuUrl
          var a = i.menuUrl.split('/')
          ob.name = a[a.length - 1]
          // ob.component = `() => import('@/views/${ob.name}/${ob.name}.vue')`
          ob.component = eval(`() => import('@/views/${ob.name}/${ob.name}.vue')`)
          ob.meta = {
            bread: {
              title: item.menuName,
              children: {
                title: i.menuName
              }
            }
          }
          ob.children = []
          obj.children.push(ob)
          i.twoMenusList.forEach(k => {
            var o = {}
            o.path = k.menuUrl
            var b = k.menuUrl.split('/')
            o.name = b[b.length - 1]
            // o.component = `() => import('@/views/${o.name}/${o.name}.vue')`
            o.component = eval(`() => import('@/views/${o.name}/${o.name}.vue')`)
            o.meta = {
              bread: {
                title: item.menuName,
                children: {
                  title: i.menuName,
                  children: {
                    title: k.menuName
                  }
                }
              }
            }
            o.children = []
            ob.children.push(o)
          })
        })
        asyncRputer.push(obj)
      })
      console.log(asyncRputer, 'asyncRputer')
      sessionStorage.setItem('asyncRputer', JSON.stringify(asyncRputer))
      this.btnList(res.data)
      await this.getbtnZuständigkeit()
      // console.log(asyncRputer);
    },
    btnList (v) {
      v.forEach(item => {
        if (item.twoMenusList && item.twoMenusList.length > 0) {
          this.btnList(item.twoMenusList)
        } else {
          this.btnsList.push(item.uuid)
        }
      })
      sessionStorage.setItem('btnList', JSON.stringify(this.btnsList))
    },
    // 获取按钮权限
    async getbtnZuständigkeit () {
      const { data: res } = await this.$http.post('/userManageServer/user/selectBtnList',
        {
          userId: sessionStorage.getItem('userId')
        })
      console.log(res, 'res.statusCoderess')
      if (res.statusCode === '200') {
        this.$store.commit('SET_ROLES', res.data)
      }
    }

  }
}
</script>
  <style lang="less" scoped>
  .login {
    background-image: url("../../assets/img_login_page_bg@2x.jpg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;

    .el-row {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;

      .el-colss {
        height: 100%;
        box-sizing: border-box;

        .el-rows {
          display: flex;
          align-items: center;

          .el-cols {
            height: 500px;

            .cenformbox {
              height: 100%;
              background-color: #fff;
              box-sizing: border-box;
              padding: 60px 40px;

              h1 {
                text-align: center;
                color: #409eff;
              }

              .el-form-item {
                width: 100%;
                margin-top: 40px;
              }

              .el-input {
                width: 100%;
              }

              .yzmitem {
                .el-input {
                  width: 50%;
                }

                .img {
                  width: 100px;
                  height: 38px;
                  border: 1px solid #0097fe;
                  float: right;
                  margin-top: 2px;
                }
              }

              .el-button {
                width: 100%;
                height: 60px;
                margin-top: 20px;
                background: linear-gradient(90deg, #5798ff, #0094f9);
                box-shadow: 0px 6px 15px 0px rgba(98, 190, 254, 0.77);
                border-radius: 10px;
              }
            }

            .img {
              height: 100%;
              width: 100%;
              //object-fit: cover;
            }
          }
        }
      }
    }
  }
  </style>
